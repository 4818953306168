import React from "react";
import IconLogoAlt from "../components/icons/logo-alternative";
import * as styles from "./404.module.scss";

const NotFoundPage = () => {
  return (
    <main className={styles["errorPage"]}>
      <section className={styles["errorPage__contentWrapper"]}>
        <div className={styles["errorPage__content"]}>
          <div className={styles["errorPage__icon"]}>
            4
            <div className={styles["errorPage__iconHolder"]}>
              <IconLogoAlt color="currentColor" height="150" width="125" />
            </div>
            4
          </div>
          <h1 className={styles["errorPage__title"]}>Page not found</h1>
          <p className={styles["errorPage__text"]}>
            The page you’re looking for has been removed or doesn’t exist.
          </p>
        </div>
      </section>
    </main>
  );
};

export default NotFoundPage;
